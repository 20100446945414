<template>
  <div>
    <b-loading v-model="loading"></b-loading>

    <div
      v-if="!loading"
      class="is-flex is-flex-wrap-wrap is-align-items-center my-4"
    >
      <h1 class="mr-5">
        <span>
          {{ $tc("device", 2) }} &ndash; {{ device.serialNumber }} &ndash;
        </span>
        {{ $t("edit") }}
      </h1>
    </div>

    <div v-if="!loading" class="card">
      <div class="card-content pt-3">
        <BackButton
          :parent-route="{ name: 'DeviceView', params: { id } }"
        ></BackButton>

        <DeviceForm
          :mode="'edit'"
          :device="device"
          @submit="submit"
        ></DeviceForm>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import BackButton from "../../components/BackButton";
import DeviceForm from "./partials/DeviceForm";

export default {
  name: "DeviceEdit",
  components: { BackButton, DeviceForm },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      device: null,
    };
  },
  mounted() {
    this.getDevice(this.id).then((device) => {
      this.device = device;
      this.loading = false;
    });
  },
  methods: {
    ...mapActions(["getDevice", "updateDevice"]),
    submit(payload) {
      this.loading = true;

      this.updateDevice({
        id: this.device.id,
        device: payload,
      })
        .then((device) => {
          this.$buefy.toast.open({
            message: this.$t("device_updated"),
            type: "is-primary",
          });
          this.$router.push({
            name: "DeviceView",
            params: { id: device.id },
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
